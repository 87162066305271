import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { VistaprintAuth } from '@vp/auth-react';
import config from '../config';

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const links: ApolloLink[] = [];
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      throw networkError;
    }
  });
  links.push(errorLink);

  const authLink = setContext(async (_, { headers }) => {
    const auth = new VistaprintAuth.WebAuth();
    const token = await auth.getToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  links.push(authLink);

  const httpLink = createHttpLink({
    uri: `${config.service.digitalDataApi.url}/api/v1`,
  });
  links.push(httpLink);

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
    defaultOptions,
  });
}
