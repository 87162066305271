export enum ErrorName {
  SPUD_ERROR = 'SPUD_ERROR',
  SPUD_REFUSAL = 'SPUD_REFUSAL',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  SUBSCRIPTION_GROUP_NOT_FOUND = 'SUBSCRIPTION_GROUP_NOT_FOUND',
  CUSTOMER_PROFILE_ERROR = 'CUSTOMER_PROFILE_ERROR',
  CART_TEMPLATE_ERROR = 'CART_TEMPLATE_ERROR',
  UPDATE_BILLING_ADDRESS_ERROR = 'UPDATE_BILLING_ADDRESS_ERROR',
  UPDATE_VAT_ID_ERROR = 'UPDATE_VAT_ID_ERROR',
  UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR',
}
