import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';

export { wrapPageElement, wrapRootElement } from './gatsby-common';

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'development') {
    whyDidYouRender(React, {
      trackAllPureComponents: false,
    });
  }
};
