import React, { PropsWithChildren } from 'react';
import { SiteConfig } from '../models/SiteConfig';

export type SiteConfigDataProps = PropsWithChildren<{
  siteConfig: SiteConfig;
}>;

export const SiteConfigDataContext = React.createContext<SiteConfigDataProps>({
  siteConfig: {
    index: {
      brand: '',
      environment: '',
      locale: '',
    },
    geoData: {
      billing: [],
    },
    uiData: {
      addressValidation: {
        loqateAPIKey: '',
        loqateEnabledCountries: [],
      },
      addressAutocomplete: {
        provider: '',
        apiKey: '',
        enabledCountries: [],
      },
      subscriptionManagement: {
        bookends: {
          hideCart: false,
          tenant: '',
        },
      },
    },
    taxIdOptions: {
      vatId: false,
      indiaGstIn: false,
    },
  },
});

export const SiteConfigDataProvider: React.FC<SiteConfigDataProps> = (props) => {
  return (
    <SiteConfigDataContext.Provider value={props}>{props.children}</SiteConfigDataContext.Provider>
  );
};
