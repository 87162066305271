import { SideBarButtonData, SidebarData } from '@vp/account-page-layout';
import { Environment, useRuntimeContext } from '@vp/om-shared-subscriptions-lib';
import React, { PropsWithChildren } from 'react';
import { MyAccountEnvironment } from '../models/enums/MyAccountEnvironment';

export type MyAccountProps = PropsWithChildren<{
  sidebarData: SidebarData;
}>;

export interface MyAccountContextProps extends MyAccountProps {
  environment: MyAccountEnvironment;
}

export const MyAccountContext = React.createContext<MyAccountContextProps>({
  sidebarData: {
    sideBarButtonData: [] as SideBarButtonData[],
    sideBarTitle: '',
  },
  environment: MyAccountEnvironment.dev,
});

export const MyAccountProvider: React.FC<MyAccountProps> = (props) => {
  const { environment } = useRuntimeContext();
  const myAccountEnvironment =
    environment === Environment.Prod ? MyAccountEnvironment.prod : MyAccountEnvironment.dev;

  const value: MyAccountContextProps = {
    ...props,
    environment: myAccountEnvironment,
  };

  return <MyAccountContext.Provider value={value}>{props.children}</MyAccountContext.Provider>;
};
