import {
  getRootClassNames,
  SWAN_BASE_URL_MAP,
  SwanHead,
  SwanProvider,
  useBrowserClasses,
} from '@vp/swan';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { SwanStyleKeys } from 'swanKeys';

export const SwanConfigurationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const swanBaseUrl = SWAN_BASE_URL_MAP.default.cdn;
  const browserClassName = useBrowserClasses();
  const bodyClassName = getRootClassNames();
  return (
    <SwanProvider swanBaseUrl={swanBaseUrl}>
      <Helmet
        htmlAttributes={{ class: browserClassName }}
        bodyAttributes={{ class: bodyClassName }}
      />
      <SwanHead renderWith={Helmet} renderStyleContentAsChildren styleSheetKeys={SwanStyleKeys} />
      {children}
    </SwanProvider>
  );
};
