import { VistaprintAuth } from '@vp/auth-react';
import { invariant } from '@vp/digital-invariant-lib';
import { useLocale } from '@vp/digital-locale-lib';
import { AccessType, useRuntimeContext } from '@vp/om-shared-subscriptions-lib';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const DEFAULT_AUTH_CONNECTION = 'VP-Customers';

const AuthContext = createContext(undefined);

type AuthProviderProps = {
  children: ReactNode;
  directAccessClientId?: string;
  directAccessConnection?: string;
};

function AuthProvider({
  children,
  directAccessClientId,
  directAccessConnection = DEFAULT_AUTH_CONNECTION,
}: AuthProviderProps): ReactElement {
  const { culture } = useLocale();
  const [webAuth, setWebAuth] = useState();
  const { accessType } = useRuntimeContext();

  const developmentMode = useMemo(() => {
    let developmentMode: boolean | Record<string, unknown> = false;

    if (accessType === AccessType.Direct) {
      invariant(directAccessClientId, 'directAccessClientId is required');
      developmentMode = {
        clientID: directAccessClientId,
      };
    }

    return developmentMode;
  }, [accessType, directAccessClientId]);

  const options = useMemo(() => {
    if (developmentMode) {
      const opt = {
        connection: directAccessConnection,
        redirectAfterSignOutCallback: () =>
          typeof window === 'undefined' ? '' : window.location.href.toString(),
        requireSession: true,
      };
      return opt;
    }
    return { requireSession: true };
  }, [directAccessConnection, developmentMode]);

  useEffect(() => {
    VistaprintAuth.init({
      culture,
      developmentMode,
      options,
    }).then(() => {
      const auth = new VistaprintAuth.WebAuth();
      setWebAuth(auth);
    });
  }, [culture, developmentMode, options]);

  return <AuthContext.Provider value={webAuth}>{children}</AuthContext.Provider>;
}

type WebAuth = {
  isSignedIn(): boolean;
  signIn(): void;
  signOut(): void;
};

function useWebAuth(): WebAuth | undefined {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useWebAuth };
