import PageConfig from './PageConfig';

export const SUBSCRIPTIONS_REDIRECT_URL_PARAM_NAME = 'redirect_url';

function getValueFromQueryString(queryStringKey: string): string | undefined {
  if (!PageConfig.isBrowser) return undefined;

  const { search } = window.location;
  if (!search) return undefined;

  const query = new URLSearchParams(search);
  return query.get(queryStringKey) || undefined;
}

function removeParamsByKey(key: string): void {
  if (PageConfig.isBrowser) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(key);

    // unfortunately this converts the : from the redirect param to unicode,
    // ie. 'subscriptions:manage' becomes 'subscriptions%3Amanage'
    // we adjust for this by decoding the param before using it
    const baseUrl = window.location.origin + window.location.pathname;
    const newUrl = urlParams.toString() === '' ? baseUrl : baseUrl + '?' + urlParams.toString();
    if (window.history.pushState) {
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }
}

function addParamToCurrentUrl(name: string, value: string): string {
  let urlParams: URLSearchParams = new URLSearchParams();
  if (window.location?.search) {
    urlParams = new URLSearchParams(window.location.search);
  }
  urlParams.append(name, value);
  return window.location.origin + window.location.pathname + '?' + urlParams.toString();
}

export { addParamToCurrentUrl, getValueFromQueryString, removeParamsByKey };
