import union from 'lodash/union';
import React, { PropsWithChildren, useContext } from 'react';

export const FEATURE_FLAG_QUERY_PARAM = 'feature';
const FEATURE_FLAG_LOCAL_STORAGE_PARAM = 'vp-subscriptions-features';

type FeatureFlagsProps = PropsWithChildren<{
  features: FeatureFlag[];
}>;

export enum FeatureFlag {
  exampleFeatureFlag = 'exampleFeatureFlag',
}

const FEATURE_FLAGS_ENABLED: FeatureFlag[] = [];

const parseFeatureValues = (params: string[]): FeatureFlag[] => {
  const featureFlags: FeatureFlag[] = [];
  const featureValues = params.reduce((acc: string[], v) => acc.concat(v.split(',')), []);
  featureValues.forEach((value) => {
    const featureValue = FeatureFlag[value as keyof typeof FeatureFlag];
    featureValue && featureFlags.push(featureValue);
  });
  return featureFlags;
};

const hasFeatureFlagsQueryParam = (): boolean | undefined => {
  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams?.get(FEATURE_FLAG_QUERY_PARAM);
    return paramValue !== null;
  }
  return undefined;
};

const getFeatureFlagsFromLocalStorage = (): FeatureFlag[] => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const localStorageParams = window.localStorage.getItem(FEATURE_FLAG_LOCAL_STORAGE_PARAM);
    const paramValues = localStorageParams?.split(',') || [];
    return parseFeatureValues(paramValues);
  }
  return [];
};

const getFeatureFlagsFromQuery = (): FeatureFlag[] => {
  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const paramValues = urlParams.getAll(FEATURE_FLAG_QUERY_PARAM);
    return parseFeatureValues(paramValues);
  }
  return [];
};

export const FeatureFlagContext = React.createContext<FeatureFlagsProps>({
  features: [],
});

export const FeatureFlagProvider: React.FC<PropsWithChildren> = (props) => {
  let features = getFeatureFlagsFromQuery();

  if (hasFeatureFlagsQueryParam()) {
    window.localStorage.setItem(FEATURE_FLAG_LOCAL_STORAGE_PARAM, features.join(','));
  } else {
    features = getFeatureFlagsFromLocalStorage();
  }

  features = union(features, FEATURE_FLAGS_ENABLED);

  return (
    <FeatureFlagContext.Provider value={{ features }}>{props.children}</FeatureFlagContext.Provider>
  );
};

export function useFeatureFlags(): FeatureFlag[] {
  const context = useContext(FeatureFlagContext);
  return context.features;
}

export function useFeatureFlagEnabled(flag: FeatureFlag) {
  const context = useContext(FeatureFlagContext);
  return context.features.includes(flag);
}
