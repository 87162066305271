const config = {
  auth: {
    directAccessCustomerViewClientId: 'H5DiFL0CZKHJtD8txfEn6iaiQ9TjYMGW',
  },
  service: {
    digitalDataApi: {
      url: process.env.DIGITAL_DATA_API_URL,
    },
    vistacart: {
      url: process.env.VISTACART_SERVICE_URL,
    },
    profileService: {
      accountId: 'ozoDdrmewShEcbUDWX8J3V',
      url: process.env.PROFILE_SERVICE_URL || '',
    },
    subscriptionService: {
      url: process.env.SUBSCRIPTION_SERVICE_URL,
    },
    paymentAccountService: {
      url: process.env.PAYMENT_ACCOUNT_SERVICE_URL,
    },
  },
};

export default config;
