import { EnvironmentToTrackingMap } from '@vp/digital-tracking-lib';
import React, { PropsWithChildren } from 'react';

export type TrackingProps = PropsWithChildren<{
  trackingKeys: EnvironmentToTrackingMap;
}>;

export const TrackingContext = React.createContext<TrackingProps>({
  trackingKeys: {
    Local: '',
    Staging: '',
    Prod: '',
  },
});

export const TrackingProvider: React.FC<TrackingProps> = (props) => {
  return <TrackingContext.Provider value={props}>{props.children}</TrackingContext.Provider>;
};
