import { ApolloProvider } from '@apollo/client';
import { SidebarData } from '@vp/account-page-layout';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { EnvironmentToTrackingMap } from '@vp/digital-tracking-lib';
import { Environment, RuntimeContextProvider } from '@vp/om-shared-subscriptions-lib';
import { AuthProvider } from 'contexts/AuthContext';
import { FeatureFlagProvider } from 'contexts/FeatureFlagContext';
import { MyAccountProvider } from 'contexts/MyAccountProvider';
import { SwanConfigurationProvider } from 'contexts/SwanConfigurationProvider';
import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from 'gatsby';
import React, { ReactElement } from 'react';
import { IdentityProvider } from './src/contexts/IdentityContext';
import LocalizationProvider, {
  LocalizationProviderProps,
} from './src/contexts/LocalizationProvider';
import {
  LocalizedPageDataProps,
  LocalizedPageDataProvider,
} from './src/contexts/LocalizedPageDataContext';
import { RoutingProps, RoutingProvider } from './src/contexts/RoutingContext';
import { SiteConfigDataProvider } from './src/contexts/SiteConfigDataContext';
import { TrackingProvider } from './src/contexts/TrackingProvider';
import { createApolloClient } from './src/hocs/apolloClient';
import { SiteConfig } from './src/models/SiteConfig';

export type PageContextType = LocalizationProviderProps &
  RoutingProps & {
    directAccessCustomerViewClientId: string;
    environment: Environment;
    indexPage?: boolean;
    localizedPageData: LocalizedPageDataProps;
    pageId: string;
    sidebarData: SidebarData;
    siteConfigData: SiteConfig;
    trackingKeys: EnvironmentToTrackingMap;
  };

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<Record<string, unknown>, PageContextType>) => {
  const {
    baseUrl,
    defaultLocaleTranslations,
    directAccessCustomerViewClientId,
    environment,
    indexPage,
    locale,
    localizedPageData,
    siteConfigData,
    sidebarData,
    trackingKeys,
    translations,
  } = props.pageContext;

  if (indexPage) {
    return element;
  }

  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <LocalizationProvider
        locale={locale}
        translations={translations}
        defaultLocaleTranslations={defaultLocaleTranslations}
      >
        <RuntimeContextProvider getEnvironment={() => environment}>
          <AuthProvider directAccessClientId={directAccessCustomerViewClientId}>
            <IdentityProvider>
              <RoutingProvider baseUrl={baseUrl}>
                <LocalizedPageDataProvider {...localizedPageData}>
                  <SiteConfigDataProvider siteConfig={siteConfigData}>
                    <MyAccountProvider sidebarData={sidebarData}>
                      <TrackingProvider trackingKeys={trackingKeys}>
                        <FeatureFlagProvider>{element}</FeatureFlagProvider>
                      </TrackingProvider>
                    </MyAccountProvider>
                  </SiteConfigDataProvider>
                </LocalizedPageDataProvider>
              </RoutingProvider>
            </IdentityProvider>
          </AuthProvider>
        </RuntimeContextProvider>
      </LocalizationProvider>
    </LocaleProvider>
  );
};

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs): ReactElement => {
  return (
    <ApolloProvider client={createApolloClient()}>
      <SwanConfigurationProvider>{element}</SwanConfigurationProvider>
    </ApolloProvider>
  );
};
