import { useIdentity, VistaprintAuth } from '@vp/auth-react';
import { useLocale } from '@vp/digital-locale-lib';
import { initializeNewRelicTrackingContainer, trackError } from '@vp/om-newrelic-tracker';
import axios from 'axios';
import config from 'config';
import { useWebAuth } from 'contexts/AuthContext';
import { CanonicalUser } from 'models/contexts/CanonicalUser';
import { CustomerProfile } from 'models/contexts/CustomerProfile';
import React, { useEffect, useState } from 'react';
import { ErrorName } from 'common/ErrorName';
import PageConfig from 'common/PageConfig';
import { getValueFromQueryString } from 'common/urlHelper';

const IS_CUSTOMER_CLAIM = 'https://claims.cimpress.io/is_customer';
const CANONICAL_ID_CLAIM = 'https://claims.cimpress.io/canonical_id';
const CIMPRESS_INTERNAL_CLAIM = 'https://claims.cimpress.io/cimpress_internal';

interface IdentityContextProps {
  canonicalUser?: CanonicalUser;
  customerProfile?: CustomerProfile;
}

type Props = {
  children: React.ReactNode;
};

const IdentityContext = React.createContext<IdentityContextProps>({});

const IdentityProvider: React.FC<Props> = (props) => {
  const auth = new VistaprintAuth.WebAuth();

  const fetchProfile = () => {
    return axios.get(
      `${config.service.profileService.url}/v1/${config.service.profileService.accountId}/profile/me`,
      {
        headers: { Authorization: auth.getAuthorizationHeader() },
      }
    );
  };

  const [customerProfile, setCustomerProfile] = useState<CustomerProfile>();
  const [canonicalUser, setUserIdentity] = useState<CanonicalUser>();
  const { identifier: locale } = useLocale();
  const webAuth = useWebAuth();
  const identity = useIdentity();

  useEffect(() => {
    if (webAuth) {
      if (webAuth.isSignedIn() && identity?.profile) {
        if (identity.profile[IS_CUSTOMER_CLAIM]) {
          setUserIdentity({
            isSignedIn: true,
            canonicalId: identity.profile[CANONICAL_ID_CLAIM],
            isShopper: true,
            authToken: identity.accessToken,
          });
        }
        if (identity.profile[CIMPRESS_INTERNAL_CLAIM]) {
          setUserIdentity({
            isSignedIn: true,
            canonicalId: identity.profile[CANONICAL_ID_CLAIM],
            isShopper: false,
            authToken: identity.accessToken,
          });
        }
      } else {
        webAuth.signIn();
      }
    }
  }, [webAuth, identity]);

  useEffect(() => {
    if (canonicalUser?.isShopper) {
      const updateProfile = async () => {
        try {
          const { data } = await fetchProfile();
          setCustomerProfile(data);
        } catch (e) {
          trackError(e as Error, {
            errorName: ErrorName.CUSTOMER_PROFILE_ERROR,
            debug: PageConfig.isDevelopment,
          });
          setCustomerProfile({
            canonicalId: canonicalUser.canonicalId,
          });
        }
      };
      if (webAuth) {
        if (webAuth.isSignedIn() && identity) {
          updateProfile();
        } else {
          webAuth.signIn();
          setCustomerProfile(undefined);
        }
      }
    } else {
      const shopperId = getValueFromQueryString('shopper_id');
      setCustomerProfile({
        canonicalId: shopperId,
      });
    }
  }, [webAuth, canonicalUser, identity]);

  const shopperCanonicalId = customerProfile?.canonicalId;

  useEffect(() => {
    if (shopperCanonicalId)
      initializeNewRelicTrackingContainer({
        shopperId: shopperCanonicalId,
        locale,
      });
  }, [locale, shopperCanonicalId]);

  return (
    <IdentityContext.Provider value={{ canonicalUser, customerProfile }}>
      {props.children}
    </IdentityContext.Provider>
  );
};

export { IdentityContext, IdentityProvider };
