import { defineMessages } from '@vp/om-i18n-utils';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from '../common/Route';

const messages = defineMessages({
  INDEX: '/',
  UPDATE_PAYMENT: '/[[subscriptionGroupAltId]]',
});

export type RoutingProps = PropsWithChildren<{
  baseUrl: string;
}>;

export type RoutingContextProps = RoutingProps & {
  useLocalizedRoute: (route: Route) => string;
  useLocalizedLink: (route: Route, options?: Record<string, unknown>) => string;
};

export const RoutingContext = React.createContext<RoutingContextProps>({
  baseUrl: '/',
  useLocalizedRoute: () => '',
  useLocalizedLink: () => '',
});

export const RoutingProvider: React.FC<RoutingProps> = (props) => {
  const { baseUrl } = props;

  // i18next default interpolation uses [[]] for param values.
  // Reach router uses different convention for route parameters.
  // To use translated route names in reach router we need to
  // replace '[[<param>]]' with ':<param>'
  const useLocalizedRoute = (route: Route) => {
    const { t } = useTranslation();
    const routeName = t(messages[route].id, { skipInterpolation: true });
    const localizedRoute = routeName.replace(/\[\[(\w+)\]\]/g, ':$1');
    return `${baseUrl}${localizedRoute}`;
  };

  // Use i18next interpolation to pass param values
  // https://www.i18next.com/translation-function/interpolation
  const useLocalizedLink = (route: Route, options?: Record<string, unknown>) => {
    const { t } = useTranslation();
    const localizedLink = `${baseUrl}${t(messages[route].id, options)}`;
    return localizedLink;
  };

  const value: RoutingContextProps = {
    baseUrl: props.baseUrl,
    useLocalizedRoute,
    useLocalizedLink,
  };

  return <RoutingContext.Provider value={value}>{props.children}</RoutingContext.Provider>;
};
