import { BOOKENDS_SWAN_STYLES } from '@vp/esi-bookends';
import { SWAN_JS_KEY_MAP, SWAN_STYLE_KEY_MAP } from '@vp/swan';

export const SwanStyleKeys = [
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.alertBox,
  SWAN_STYLE_KEY_MAP.controlIcon,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.legacyModalDialog,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.legacyPopover,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.tabs,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.listbox,
  SWAN_STYLE_KEY_MAP.legacyListbox,
  SWAN_STYLE_KEY_MAP.toggleSwitch,
  SWAN_STYLE_KEY_MAP.stepIndicator,
  SWAN_STYLE_KEY_MAP.table,
  SWAN_STYLE_KEY_MAP.standardHero,
  SWAN_STYLE_KEY_MAP.visible,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.progressiveImage,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.spinner,
  ...BOOKENDS_SWAN_STYLES,
];

export const SwanScriptKeys = [
  SWAN_JS_KEY_MAP.modalDialog,
  SWAN_JS_KEY_MAP.popover,
  SWAN_JS_KEY_MAP.progressiveImage,
];
