import React, { PropsWithChildren } from 'react';

export type PhoneContact = {
  treatment?: string;
  schedule: string[];
  phoneNumber: string;
};

export type CareContactDataModel = {
  defaultCareContact: PhoneContact;
  phoneContactMap: PhoneContact[];
  helpCenterRoute: string;
};

export type LocalizedPageDataProps = PropsWithChildren<{
  careContactData: CareContactDataModel;
  myAccountUrl: string;
  privacyCookiePolicyUrl: string;
  subscriptionManageUrl: string;
  subscriptionReactivateUrl: string;
  subscriptionsHomeUrl: string;
  termsAndConditionsUrl: string;
  updatePaymentUrl: string;
}>;

export const LocalizedPageDataContext = React.createContext<LocalizedPageDataProps>({
  careContactData: {
    defaultCareContact: {
      schedule: [],
      phoneNumber: '',
    },
    phoneContactMap: [],
    helpCenterRoute: '',
  },
  myAccountUrl: '',
  privacyCookiePolicyUrl: '',
  subscriptionManageUrl: '',
  subscriptionReactivateUrl: '',
  subscriptionsHomeUrl: '',
  termsAndConditionsUrl: '',
  updatePaymentUrl: '',
});

export const LocalizedPageDataProvider: React.FC<LocalizedPageDataProps> = (props) => {
  return (
    <LocalizedPageDataContext.Provider value={props}>
      {props.children}
    </LocalizedPageDataContext.Provider>
  );
};
